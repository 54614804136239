<template>
  <v-container row wrap fluid justify-center fill-height class="general-container">
    <v-flex lg5  v-if="!$vuetify.breakpoint.md">
    </v-flex>
    <v-flex xs12 md6 lg5>
      <v-layout justify="center" align="center">
        <v-col  align-self="center">
        <v-form>
          <v-alert
            width="515px"
            v-model="alert"
            text
            outlined
            color="info"
            elevation="20"
            transition="scale-transition">
            <h3 class="headline">Importante!!</h3>
            <v-divider
              class="my-4 info"
              style="opacity: 0.22">
            </v-divider>
            <div>En estos momentos el usuario <b>{{ username }}</b> cuenta con una sesión activa. ¿Desea cerrarla?</div>
            <br>
            <v-row
              align="center"
              no-gutters>
              <v-col class="shrink">
                <v-btn
                  color="error"
                  :disabled="btnEnable"
                  @click="alert=false">
                  Cancelar
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="shrink">
                <v-btn
                  @click="LoginForce()"
                  :disabled="btnEnable"
                  color="success">
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-card class="card" :elevation="this.$vuetify.breakpoint.xs ? 5 : 0">
            <v-card-title>
              <v-col cols="12" class="logo">
                  <img src="../assets/img/logo-company.png">
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-col sm="10" md="10" lg="10" xl="10" class="label">
                <span class="titleslight--text">Usuario</span>
                <span class="titleslight--text ml-1">*</span>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <v-text-field
                  v-model="username"
                  append-icon="fa fa-user-circle"
                  :rules="userValidation"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="label">
                <span class="titleslight--text">Contraseña</span>
                <span class="titleslight--text ml-1">*</span>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <v-text-field
                  v-model="password"
                  :append-icon="showPass ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  :rules="passwordValidation"
                  :type="showPass ? 'text' : 'password'"
                  v-on:keypress="validateEnter"
                  name="input-10-1"
                  @click:append="showPass = !showPass"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <v-btn
                  class="font-weight-bold"
                  @click="Login"
                  size="large"
                  color="primary">
                  Ingresar
                </v-btn>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <OlvideContra v-model="restorePass"></OlvideContra>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <SocialMedia></SocialMedia>
              </v-col>
            </v-card-text>
            <v-col sm="12" md="12" lg="12" xl="12">               
              <v-footer absolute class="footer">
                <v-spacer></v-spacer>
                <div class="font-weight-bold">&copy; Derechos Reservados TechBoss V1.1</div>
                <v-spacer></v-spacer>
              </v-footer>
            </v-col>
          </v-card>
        </v-form>
      </v-col>
      </v-layout>
    </v-flex>
  </v-container>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
import OlvideContra from "@/components/RestorePassword.vue"
import SocialMedia from "@/components/SocialMedia.vue"
var md5 = require('md5');
export default {
  name:'login',
  components: {
    SocialMedia,
    OlvideContra
  },
  data() {
    return {
      username        : '',
      password        : '',
      bValidate       : false,
      showPass        : false,
      restorePass     : false,
      alert           : false,
      btnEnable       : false,
      userValidation  : [
        v => !!v || 'El usuario es requerido.'
      ],
      passwordValidation : [
        v => !!v || 'La contraseña es requerida.',
        v => v.length >= 5 || 'Minimo 5 carácteres',
        v => /^[a-zA-Z0-9!#-+/.*@·~%&¬()=?¿¡{}[\]]{5,}$/.test(v) || 'La contraseña contiene caracteres invalidos.'
      ],
    }
  },
  mounted(){
    window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection || false
    if (window.RTCPeerConnection) {
      var pc = new RTCPeerConnection({iceServers:[]}), noop = function() {}
      pc.createDataChannel('');
      pc.createOffer(pc.setLocalDescription.bind(pc), noop);
      pc.onicecandidate = function(event) {
        if (event && event.candidate && event.candidate.candidate) {
          var s = event.candidate.candidate.split('\n');
          sessionStorage.ip = s[0].split(' ')[4];
        }
      }
    }
  },
  methods: {
    validateInputs() {
      this.bValidate = false;
      if (this.username !== "" && this.password !== "" &&
          /^[a-zA-Z0-9!#-+/.*@·~%&¬()=?¿¡{}[\]]{5,}$/.test(this.password)) {
        this.bValidate = true;
      }
      return this.bValidate;
    },
    validateEnter: function(event) {
      if (event.keyCode == 13) {
        this.Login();
      }
    },
    Login() {
      if (this.validateInputs()) {
        let oBody = {
          "username": this.username.trim(),
          "password": md5(this.password),
          "origen": "WEB",
          "ip": sessionStorage.ip
        };
        axiosServices.postConfiguration('login', oBody)
        .then(aResponse => {
          if (aResponse.sStatus == "success") {
             this.onResponseSuccess(aResponse)
          } else {
            if (aResponse.nStatusCode == 210) {
              this.alert = true;
              this.$emit("input", this.alert);
            }
            this.$store.dispatch('notificaciones/SetMensaje', `${aResponse.sMessage}`);
            this.$store.dispatch('notificaciones/ShowNotificacion', true);
            this.$store.dispatch('notificaciones/SetColor','error')

          }
        });
      } else {
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/SetMensaje', "Uno de los campos se encuentra vacio ó la contraseña contiene caracteres invalidos.");
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
    LoginForce() {
      this.btnEnable = true
      if (this.validateInputs()) {
        let oBody = {
          "username": this.username.trim(),
          "password": md5(this.password),
          "origen": "WEB",
          "ip": sessionStorage.ip
        };
        axiosServices.postConfiguration('loginForce', oBody)
        .then(aResponse => {
          if (aResponse.sStatus == "success") {
            this.onResponseSuccess(aResponse)
          }
        });
      } else {
        this.$store.dispatch('notificaciones/SetMensaje', "Uno de los campos se encuentra vacio ó la contraseña contiene caracteres invalidos.");
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
    onResponseSuccess(aResponse){
      this.$store.dispatch('setLogin', true);
      this.$store.dispatch('notificaciones/SetColor','success')
      this.$store.dispatch('saveToken', aResponse.aData['sToken']);
      this.$store.dispatch('saveIdUser', aResponse.aData['idUsuarios']);
      this.$store.dispatch('saveIdDeparment', aResponse.aData['idDepartamentos']);
      this.$store.dispatch('saveIdPerfil', aResponse.aData['idPerfil']);
      this.$store.dispatch('saveIdSubProyecto', aResponse.aData['idsubproyecto']);
      this.$store.dispatch('saveSubdominioTemporal', aResponse.aData['subdominioTemporal']);
      this.$store.dispatch('saveIdCliente', aResponse.aData['idCliente']);
      this.$store.dispatch('saveDiccionario', aResponse.aData['aDiccionario']);

      this.$router.replace({name : 'DashBoard'})

    }
  },
}
</script>
<!-- scope es para los estilos locales -->
<style lang="scss" scoped>
.footer {
  font-size: 0.6em;
}
.logo {
  height: 120px;
  margin: auto;
  padding: 5px;
  text-align: center;
  background: white;
  img{
    height: 100%;
  }
}
.contenedor{
  margin: auto;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
}
.label {
  margin: auto;
  padding: 0px 10px 0px 10px;
}
.titulo {
  font-size: 2em;
  text-align: center;
}
.card{
  // background:rgba($color: #FFF, $alpha: 1);
  background: var(--v-backwhite-base);
  border-radius: 10px;
  overflow: hidden;
}

.general-container{
  margin: 0;
  height: 100vh;
  background-image: url('../assets/img/fondo-seif-2.png');
  background-repeat: no-repeat;
  background-color:var(--v-backlogin-base);
  background-size:contain;
  transition: all 0.3s ease-in-out;
  @media (max-width: 769px) and (orientation:portrait) {
    background-size: cover;
  }
}
</style>