<template>
  <v-container>
    <v-row justify="center">
      <span @click="crear()" class="punto">¿Olvidaste Contraseña?</span>
      <v-dialog v-model="value" persistent max-width="600px">
        <v-card>
          <v-toolbar
            color="headformsdark"
            height="45"
            elevation="0"
            dense dark>
            <v-spacer></v-spacer>
            <span class="mdlHeadForms font-weight-medium">Restablecer contraseña</span>
            <v-spacer></v-spacer>
            <v-btn 
              color="btncolor-dark"
              dark
              @click="CloseDialog()"
              icon
              small
              outlined>
              <v-icon size="14px">fa fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-form>
              <span class="titleslight--text">Usuario</span>
              <span class="titleslight--text ml-1">*</span>
              <v-text-field
                v-model="user"
                :rules="userValidacion"
                outlined
                dense
                required>
              </v-text-field>
              <span class="titleslight--text">Correo</span>
              <span class="titleslight--text ml-1">*</span>
              <v-text-field 
                v-model="email"
                :rules="emailValidacion"
                outlined
                dense
                required>
              </v-text-field>
              <small class="d-flex justify-end"><span class="titleslight--text">* Campo Obligatorio</span></small>
            </v-form>
          </v-card-text>
          <!-- <v-divider></v-divider> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
            class="font-weight-bold"
              color="btncolor"  
              @click="restorePass()"
              dark>
              Restablecer
            </v-btn> -->
            <v-btn 
            dark outlined color="btncolor"
            class="btn-rounded" 
            depressed @click="restorePass()"> Restablecer 
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
export default {
  name:'restorepassword',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: '',
      email: '',
      Activo: false,
      dialog: false,
      bValidate: false,
      emailValidacion: [
        v => !!v || 'Correo es requerido',
        v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(v) || 'Dirección de correo no valida',
      ],
      userValidacion: [
        v => !!v || 'Usuario es requerido'
      ],
    }
  },
  methods: {
    crear() {
      this.dialog = true;
      this.$emit("input", this.dialog);
    },
    validateInputs() {
      this.bValidate = false;
      if (this.user !== "" && this.email !== "" &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(this.email)) {
        this.bValidate = true;
      }
      return this.bValidate;
    },
    restorePass() {
      if (this.validateInputs()) {
        let oBody = {
          "sLogin": this.user,
          "sEmail": this.email
        };
        axiosServices.postConfiguration('restore_password', oBody)
        .then(aResponse => {
          if (aResponse.nStatusCode == 200) {
            this.$store.dispatch('notificaciones/SetMensaje', "Le hemos enviado un enlace de recuperación a su dirección de correo electrónico.");
            this.$store.dispatch('notificaciones/SetColor','success')
            this.$store.dispatch('notificaciones/ShowNotificacion', true);
            this.CloseDialog();
          }
        });
      } else {
        this.$store.dispatch('notificaciones/SetMensaje', "Uno de los campos se encuentra vacio.");
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
    CloseDialog() {
      this.user = '';
      this.email = '';
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
  },
}
</script>
