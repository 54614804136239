<template>
  <div class="contenedor pa-3">
      <li>
        <a href="https://twitter.com/teshboss" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/TeshBossSAS" target="_blank">
          <i class="fab fa-facebook"></i>
        </a>
      </li>
      <li>
        <a href="https://co.linkedin.com/company/teshboss" target="_blank">
          <i class="fab fa-linkedin"></i>
        </a>
      </li>
      <li>
        <a href="https://techboss.com.co/" target="_blank">
          <i class="fa fa-at"></i>
        </a>
      </li>
  </div>    
</template>
<script>
export default {
    name:'SocialMedia'
}
</script>
<style>
.contenedor{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contenedor li{
    list-style: none;
    margin: 0 5px;
}
.contenedor li a{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-decoration: none;
    color: #000;
    font-size: 20px;
    transition: 0.5s;
}
.contenedor li a:hover{
    color: #ffffff;
}
.contenedor li a:before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    border-radius: 50%;
    z-index: -1;
    transform: scale(0);
    transition: 0.5s;
}
.contenedor li a:hover:before{
    transform: scale(1);
}
.contenedor li:nth-child(1) a:hover{
  color: #55acee;
}
.contenedor li:nth-child(2) a:hover{
  color: #3b5999;
}
.contenedor li:nth-child(3) a:hover{
  color: #0077b5;
}
.contenedor li:nth-child(4) a:hover{
  color: #3b5999;
}
</style>
